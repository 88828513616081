import { BORDER_RADIUS_XS, BORDER_WIDTH_4, type ColorName, SPACING_XS } from 'design-tokens'
import { type AriaAttributes, type PropsWithChildren, useMemo } from 'react'
import { uidWithPrefix } from 'uid'
import { Card } from './Card'
import { Text } from './Text'

type Kind = 'info' | 'warning'

export const suggestionKindsColors: Record<
  Kind,
  {
    background: ColorName
    borderColor: ColorName
    rectangleColor: ColorName
  }
> = {
  info: { background: 'primary-10', borderColor: 'primary-40', rectangleColor: 'primary' },
  warning: { background: 'yellow-20', borderColor: 'yellow-20', rectangleColor: 'yellow-60' },
} as const

export type SuggestionProps =
  | {
      children?: never
      description: string
      kind: Kind
      title?: string
    }
  | PropsWithChildren<{
      description?: never
      kind: Kind
      title?: never
    }>

export const Suggestion = ({ children, description, kind, title }: SuggestionProps) => {
  const uuid = useMemo(() => uidWithPrefix('suggestion'), [])

  const ariaAttrs: AriaAttributes = useMemo(
    () => ({
      'aria-describedby': `description_${uuid}`,
      ...(title && { 'aria-labelledby': `title_${uuid}` }),
    }),
    [title, uuid],
  )

  return (
    <Card
      {...(!children && ariaAttrs)}
      $backgroundColorName={suggestionKindsColors[kind].background}
      $borderColorName={suggestionKindsColors[kind].borderColor}
      $borderColorNameLeft={suggestionKindsColors[kind].rectangleColor}
      $borderRadius={BORDER_RADIUS_XS}
      $borderSizeLeft={BORDER_WIDTH_4}
      $elevationName="0"
      $gap={SPACING_XS}
      $overflow="hidden"
      $p={SPACING_XS}
      $position="relative"
      as="aside"
      role="note"
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <>
          {title && (
            <Text as="p" colorName="black" fontWeight="600" id={`title_${uuid}`} kind="caption">
              {title}
            </Text>
          )}
          <Text as="p" colorName="black" id={`description_${uuid}`} kind="caption">
            {description}
          </Text>
        </>
      )}
    </Card>
  )
}
