import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisOff = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#off-clip-path)">
      <g clipPath="url(#off-clip-path-2)" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4">
        <path d="M15.7861 4.25879c1.1921.59305 3.7801 1.9457 6.4171 3.7447.3603.24528.6218.61652.7349 1.04367.1131.42715.0702.88068-.1208 1.27504-1.4031 2.9098-2.9879 5.4035-3.7197 6.5074-.2637.4016-.6366.7145-1.073.9006-1.6653.7073-6.3342 2.5019-12.40409 3.2523-.78775.0973-1.55217-.2104-1.90873-.9405-.44604-.9134-1.06891-2.5364-1.71518-5.3268-.55942-2.4124-.83832-4.0232-.9767-5.06391-.137691-1.03812.45108-1.94789 1.39817-2.32936C7.91932 5.10648 12.6025 4.27774 14.361 4.02417c.4862-.06963.9824.01195 1.4251.23462Z" />
        <path d="M16.4627 15.2094c.2477-.404.2964-.8876.1457-1.4499-.1486-.5548-.4316-.9457-.8481-1.1717-.4164-.2256-.8871-.2685-1.4113-.1281-.5313.1424-.9215.416-1.1693.8196-.2406.4021-.2862.8806-.1375 1.4358.1508.5626.4306.9578.84 1.1857.4164.2257.891.2675 1.4224.1251.5242-.1404.9103-.4129 1.1581-.8165Zm-8.56587-3.4383c.24769-.404.29632-.8876.14566-1.4498-.14864-.55478-.4316-.9457-.84811-1.17173-.4164-.22563-.88705-.26855-1.41128-.12808-.53181.1425-.92151.41594-1.16931.81955-.24056.40206-.28625.88066-.1375 1.43576.15077.5627.43057.9579.83994 1.1858.41641.2256.89062.2676 1.42243.1251.52423-.1405.91036-.413 1.15817-.8166Zm5.91027-4.8995L7.2805 18.176" />
      </g>
    </g>
    <defs>
      <clipPath id="off-clip-path">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
      <clipPath id="off-clip-path-2">
        <path d="M0 3h24v19H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)
