import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisCheckCircle = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0057 23c-1.5173 0-2.94017-.2867-4.2687-.8601-1.32853-.5658-2.49854-1.3542-3.51003-2.3652-1.0115-1.011-1.80408-2.1804-2.37777-3.5082C1.28307 14.9311 1 13.5089 1 12c0-1.5165.28307-2.93861.8492-4.26646.57369-1.32785 1.36627-2.49726 2.37777-3.50823C5.23846 3.21433 6.40847 2.42593 7.737 1.86008 9.06553 1.28669 10.4884 1 12.0057 1c1.5172 0 2.9363.28669 4.2573.86008 1.3285.56585 2.4985 1.35425 3.51 2.36523 1.0115 1.01097 1.8003 2.18038 2.3665 3.50823C22.7132 9.06139 23 10.4835 23 12c0 1.5089-.2868 2.9311-.8605 4.2665-.5662 1.3278-1.355 2.4972-2.3665 3.5082-1.0115 1.011-2.1815 1.7994-3.51 2.3652-1.321.5734-2.7401.8601-4.2573.8601Zm-1.1889-5.7377c.3849 0 .6982-.1848.9398-.5545l5.0159-7.86521c.0755-.11317.1397-.23011.1925-.35082.0604-.12826.0906-.25274.0906-.37346 0-.26406-.1019-.47531-.3057-.63374-.1963-.16598-.419-.24897-.6681-.24897-.3246 0-.5963.17729-.8152.53189l-4.4951 7.20881-2.12868-2.716c-.12832-.1735-.26042-.2943-.39629-.3622-.12833-.0679-.27175-.1018-.43027-.1018-.26419 0-.48687.0943-.66804.2829-.17361.1811-.26042.4037-.26042.6677 0 .249.09436.4904.28307.7243l2.64951 3.2479c.14342.1811.29442.3169.45292.4074.1585.0906.3397.1358.5435.1358Z"
      fill={color}
    />
  </svg>
)
