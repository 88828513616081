/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `Degular Mono, monospace` */
export const FONT_FAMILY_DEGULAR_MONO = `var(--${DESIGN_TOKENS_PREFIX}-font-family-degular-mono)` as const
export const FONT_FAMILY_DEGULAR_MONO_VALUE = 'Degular Mono, monospace'

/** `Degular Display, serif` */
export const FONT_FAMILY_DEGULAR_DISPLAY = `var(--${DESIGN_TOKENS_PREFIX}-font-family-degular-display)` as const
export const FONT_FAMILY_DEGULAR_DISPLAY_VALUE = 'Degular Display, serif'

/** `Degular Text, sans-serif` */
export const FONT_FAMILY_DEGULAR_TEXT = `var(--${DESIGN_TOKENS_PREFIX}-font-family-degular-text)` as const
export const FONT_FAMILY_DEGULAR_TEXT_VALUE = 'Degular Text, sans-serif'
