import { cssvarColor } from 'design-tokens'
import { assertNever } from 'functions'
import {
  Alarm,
  Apple,
  AppleImac2021,
  AppleMac,
  Archive,
  ArrowDown,
  ArrowDownCircle,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUpCircle,
  Attachment,
  BadgeCheck,
  Bell,
  BellNotification,
  BinMinusIn,
  Bookmark,
  BookmarkBook,
  BookStack,
  Brain,
  Building,
  Calendar,
  ChatBubbleEmpty,
  ChatBubbleQuestion,
  ChatBubbleWarning,
  ChatLines,
  Check,
  CheckCircle,
  Community,
  Computer,
  Copy,
  CreditCard,
  CreditCardSlash,
  Donate,
  DoubleCheck,
  Download,
  Drag,
  Edit,
  EditPencil,
  Expand,
  Eye,
  EyeClosed,
  Facebook,
  FacebookTag,
  FastRightCircle,
  Finder,
  Folder,
  Gift,
  Google,
  GoogleDocs,
  GraduationCap,
  Group,
  HeadsetHelp,
  Home,
  HomeHospital,
  HomeSimpleDoor,
  Hospital,
  Hourglass,
  type Iconoir,
  Infinite,
  InfoCircle,
  Instagram,
  JournalPage,
  Language,
  Laptop,
  Lifebelt,
  LineSpace,
  Link,
  Linkedin,
  List,
  Lock,
  LockSquare,
  LogOut,
  LongArrowDownRight,
  Mail,
  MapPin,
  Medal1st,
  Medal,
  MediaImage,
  Menu,
  MenuScale,
  MessageText,
  Microphone,
  MicrophoneMute,
  Minus,
  MinusCircle,
  MoreHoriz,
  MoreVert,
  MultiplePagesPlus,
  MultiWindow,
  NavArrowDown,
  NavArrowLeft,
  NavArrowRight,
  NavArrowUp,
  OneFingerSelectHandGesture,
  OnTag,
  OpenInWindow,
  PageEdit,
  PageFlip,
  PagePlus,
  PageStar,
  PasteClipboard,
  Paypal,
  PcCheck,
  PeaceHand,
  PeopleTag,
  Percentage,
  PercentageCircle,
  PharmacyCrossTag,
  Phone,
  Plus,
  PrivacyPolicy,
  ProfileCircle,
  Prohibition,
  QuestionMark,
  ReceiveEuros,
  Reduce,
  RefreshDouble,
  Repeat,
  ReportColumns,
  Reports,
  Restart,
  Search,
  Send,
  SendDiagonal,
  SendMail,
  Server,
  Settings,
  ShareIos,
  SmartphoneDevice,
  SoundHigh,
  SoundOff,
  Sphere,
  Star,
  StarSolid,
  StatsUpSquare,
  ThreeStars,
  Timer,
  TimerOff,
  TransitionLeft,
  TransitionRight,
  Trash,
  Twitter,
  User,
  UserBadgeCheck,
  UserCircle,
  UserLove,
  UserScan,
  UserStar,
  UserXmark,
  VideoCamera,
  VideoCameraOff,
  ViewStructureUp,
  Wallet,
  WarningCircle,
  WarningCircleSolid,
  Xmark,
  XmarkCircle,
} from 'iconoir-react'
import { useMemo } from 'react'
import { type IconName, iconNames, type IconProps } from '../types'
import { AmericanExpress } from './AmericanExpress'
import { ArrowLeftToLine } from './ArrowLeftToLine'
import { ArrowRightToLine } from './ArrowRightToLine'
import { ChevronLeftCircle } from './ChevronLeftCircle'
import { ChevronRightCircle } from './ChevronRightCircle'
import { DeleteCircle } from './DeleteCircle'
import { Intercom } from './Intercom'
import { LogoApple } from './LogoApple'
import { LogoFacebook } from './LogoFacebook'
import { LogoGoogle } from './LogoGoogle'
import { Maestro } from './Maestro'
import { Mastercard } from './Mastercard'
import { PathCoaching } from './PathCoaching'
import { PathCoachingSolid } from './PathCoachingSolid'
import { PathCouples } from './PathCouples'
import { PathCouplesSolid } from './PathCouplesSolid'
import { PathMyself } from './PathMyself'
import { PathMyselfSolid } from './PathMyselfSolid'
import { PathNutritionDca } from './PathNutritionDca'
import { PathNutritionDcaSolid } from './PathNutritionDcaSolid'
import { PathNutritionWeightLoss } from './PathNutritionWeightLoss'
import { PathNutritionWeightLossSolid } from './PathNutritionWeightLossSolid'
import { PathPsychiatry } from './PathPsychiatry'
import { PathPsychiatrySolid } from './PathPsychiatrySolid'
import { PathSexology } from './PathSexology'
import { PathSexologySolid } from './PathSexologySolid'
import { PostePay } from './PostePay'
import { PoweredByStripe } from './PoweredByStripe'
import { QuestionMarkCircle } from './QuestionMarkCircle'
import { ReportsExcellent } from './ReportsExcellent'
import { ReportsGood } from './ReportsGood'
import { ReportsPoor } from './ReportsPoor'
import { SerenisAgenda } from './SerenisAgenda'
import { SerenisAgendaSolid } from './SerenisAgendaSolid'
import { SerenisBlackLivesMatter } from './SerenisBlackLivesMatter'
import { SerenisCalendar } from './SerenisCalendar'
import { SerenisCalendarPlus } from './SerenisCalendarPlus'
import { SerenisCalendarSolid } from './SerenisCalendarSolid'
import { SerenisChat } from './SerenisChat'
import { SerenisChatSolid } from './SerenisChatSolid'
import { SerenisCheck } from './SerenisCheck'
import { SerenisCheckCircle } from './SerenisCheckCircle'
import { SerenisClock } from './SerenisClock'
import { SerenisClose } from './SerenisClose'
import { SerenisCode } from './SerenisCode'
import { SerenisCommunity } from './SerenisCommunity'
import { SerenisContactPhonebook } from './SerenisContactPhonebook'
import { SerenisDeadline } from './SerenisDeadline'
import { SerenisGeneric } from './SerenisGeneric'
import { SerenisHome } from './SerenisHome'
import { SerenisHomeSolid } from './SerenisHomeSolid'
import { SerenisJournaling } from './SerenisJournaling'
import { SerenisJournalingSolid } from './SerenisJournalingSolid'
import { SerenisLeft } from './SerenisLeft'
import { SerenisLink } from './SerenisLink'
import { SerenisMemberGetMember } from './SerenisMemberGetMember'
import { SerenisMenu } from './SerenisMenu'
import { SerenisMenuSolid } from './SerenisMenuSolid'
import { SerenisMoneyBox } from './SerenisMoneyBox'
import { SerenisOff } from './SerenisOff'
import { SerenisPatients } from './SerenisPatients'
import { SerenisPatientsSolid } from './SerenisPatientsSolid'
import { SerenisQuestion } from './SerenisQuestion'
import { SerenisRight } from './SerenisRight'
import { SerenisRingingBell } from './SerenisRingingBell'
import { SerenisShippingBox } from './SerenisShippingBox'
import { SerenisSidebarCollapse } from './SerenisSidebarCollapse'
import { SerenisSidebarExpand } from './SerenisSidebarExpand'
import { SerenisSmartphone } from './SerenisSmartphone'
import { SerenisSticker } from './SerenisSticker'
import { SerenisStickerSquare } from './SerenisStickerSquare'
import { SerenisSupport } from './SerenisSupport'
import { SerenisTherapy } from './SerenisTherapy'
import { SerenisTherapySolid } from './SerenisTherapySolid'
import { SerenisTicket } from './SerenisTicket'
import { SerenisXMarkCircle } from './SerenisXMarkCircle'
import { Visa } from './Visa'
import { Whatsapp } from './Whatsapp'

export type MemoIconElementProps = {
  color: string
  fill: string
  height: IconProps['size']
  width: IconProps['size']
}

type MemoIconElement = (props: MemoIconElementProps) => JSX.Element | ReturnType<typeof Iconoir>

export const Icon = ({ colorName, fillColorName, name, size }: IconProps) => {
  const Component = useMemo((): MemoIconElement | null => {
    switch (name) {
      case 'alarm':
        return Alarm
      case 'american-express':
        return AmericanExpress
      case 'apple-imac-2021':
        return AppleImac2021
      case 'apple-mac':
        return AppleMac
      case 'apple':
        return Apple
      case 'archive':
        return Archive
      case 'arrow-down-circle':
        return ArrowDownCircle
      case 'arrow-down':
        return ArrowDown
      case 'arrow-left-circle':
        return ArrowLeftCircle
      case 'arrow-left-to-line':
        return ArrowLeftToLine
      case 'arrow-left':
        return ArrowLeft
      case 'arrow-right-circle':
        return ArrowRightCircle
      case 'arrow-right-to-line':
        return ArrowRightToLine
      case 'arrow-right':
        return ArrowRight
      case 'arrow-up-circle':
        return ArrowUpCircle
      case 'attachment':
        return Attachment
      case 'badge-check':
        return BadgeCheck
      case 'bell-notification':
        return BellNotification
      case 'bell':
        return Bell
      case 'bin-minus-in':
        return BinMinusIn
      case 'book-stack':
        return BookStack
      case 'bookmark-book':
        return BookmarkBook
      case 'bookmark':
        return Bookmark
      case 'brain':
        return Brain
      case 'building':
        return Building
      case 'calendar':
        return Calendar
      case 'chat-bubble-empty':
        return ChatBubbleEmpty
      case 'chat-bubble-question':
        return ChatBubbleQuestion
      case 'chat-bubble-warning':
        return ChatBubbleWarning
      case 'chat-lines':
        return ChatLines
      case 'check-circle':
        return CheckCircle
      case 'check':
        return Check
      case 'chevron-left-circle':
        return ChevronLeftCircle
      case 'chevron-right-circle':
        return ChevronRightCircle
      case 'community':
        return Community
      case 'computer':
        return Computer
      case 'copy':
        return Copy
      case 'credit-card-slash':
        return CreditCardSlash
      case 'credit-card':
        return CreditCard
      case 'delete-circle':
        return DeleteCircle
      case 'donate':
        return Donate
      case 'double-check':
        return DoubleCheck
      case 'download':
        return Download
      case 'drag':
        return Drag
      case 'edit-pencil':
        return EditPencil
      case 'edit':
        return Edit
      case 'expand':
        return Expand
      case 'eye-closed':
        return EyeClosed
      case 'eye':
        return Eye
      case 'facebook':
        return Facebook
      case 'facebook-tag':
        return FacebookTag
      case 'fast-right-circle':
        return FastRightCircle
      case 'finder':
        return Finder
      case 'folder':
        return Folder
      case 'gift':
        return Gift
      case 'google-docs':
        return GoogleDocs
      case 'google':
        return Google
      case 'graduation-cap':
        return GraduationCap
      case 'group':
        return Group
      case 'headset-help':
        return HeadsetHelp
      case 'home-hospital':
        return HomeHospital
      case 'home-simple-door':
        return HomeSimpleDoor
      case 'home':
        return Home
      case 'hospital':
        return Hospital
      case 'hourglass':
        return Hourglass
      case 'infinite':
        return Infinite
      case 'info-circle':
        return InfoCircle
      case 'instagram':
        return Instagram
      case 'intercom':
        return Intercom
      case 'journal-page':
        return JournalPage
      case 'language':
        return Language
      case 'laptop':
        return Laptop
      case 'lifebelt':
        return Lifebelt
      case 'line-space':
        return LineSpace
      case 'link':
        return Link
      case 'linkedin':
        return Linkedin
      case 'list':
        return List
      case 'lock-square':
        return LockSquare
      case 'lock':
        return Lock
      case 'log-out':
        return LogOut
      case 'logo-apple':
        return LogoApple
      case 'logo-facebook':
        return LogoFacebook
      case 'logo-google':
        return LogoGoogle
      case 'long-arrow-down-right':
        return LongArrowDownRight
      case 'maestro':
        return Maestro
      case 'mail':
        return Mail
      case 'map-pin':
        return MapPin
      case 'mastercard':
        return Mastercard
      case 'medal-1st':
        return Medal1st
      case 'medal':
        return Medal
      case 'media-image':
        return MediaImage
      case 'menu-scale':
        return MenuScale
      case 'menu':
        return Menu
      case 'message-text':
        return MessageText
      case 'microphone-mute':
        return MicrophoneMute
      case 'microphone':
        return Microphone
      case 'minus-circle':
        return MinusCircle
      case 'minus':
        return Minus
      case 'more-horiz':
        return MoreHoriz
      case 'more-vert':
        return MoreVert
      case 'multi-window':
        return MultiWindow
      case 'multiple-pages-plus':
        return MultiplePagesPlus
      case 'nav-arrow-down':
        return NavArrowDown
      case 'nav-arrow-left':
        return NavArrowLeft
      case 'nav-arrow-right':
        return NavArrowRight
      case 'nav-arrow-up':
        return NavArrowUp
      case 'on-tag':
        return OnTag
      case 'one-finger-select-hand-gesture':
        return OneFingerSelectHandGesture
      case 'open-in-window':
        return OpenInWindow
      case 'page-edit':
        return PageEdit
      case 'page-flip':
        return PageFlip
      case 'page-plus':
        return PagePlus
      case 'page-star':
        return PageStar
      case 'paste-clipboard':
        return PasteClipboard
      case 'path-coaching':
        return PathCoaching
      case 'path-coaching-solid':
        return PathCoachingSolid
      case 'path-couples':
        return PathCouples
      case 'path-couples-solid':
        return PathCouplesSolid
      case 'path-myself':
        return PathMyself
      case 'path-myself-solid':
        return PathMyselfSolid
      case 'path-nutrition-dca':
        return PathNutritionDca
      case 'path-nutrition-dca-solid':
        return PathNutritionDcaSolid
      case 'path-nutrition-weight-loss':
        return PathNutritionWeightLoss
      case 'path-nutrition-weight-loss-solid':
        return PathNutritionWeightLossSolid
      case 'path-psychiatry':
        return PathPsychiatry
      case 'path-psychiatry-solid':
        return PathPsychiatrySolid
      case 'path-sexology':
        return PathSexology
      case 'path-sexology-solid':
        return PathSexologySolid
      case 'paypal':
        return Paypal
      case 'pc-check':
        return PcCheck
      case 'peace-hand':
        return PeaceHand
      case 'people-tag':
        return PeopleTag
      case 'percentage-circle':
        return PercentageCircle
      case 'percentage-round':
        return Percentage
      case 'pharmacy-cross-tag':
        return PharmacyCrossTag
      case 'phone':
        return Phone
      case 'plus':
        return Plus
      case 'poste-pay':
        return PostePay
      case 'powered-by-stripe':
        return PoweredByStripe
      case 'privacy-policy':
        return PrivacyPolicy
      case 'profile-circle':
        return ProfileCircle
      case 'prohibition':
        return Prohibition
      case 'question-mark':
        return QuestionMark
      case 'question-mark-circle':
        return QuestionMarkCircle
      case 'receive-euros':
        return ReceiveEuros
      case 'reduce':
        return Reduce
      case 'refresh-double':
        return RefreshDouble
      case 'repeat':
        return Repeat
      case 'report-columns':
        return ReportColumns
      case 'reports-excellent':
        return ReportsExcellent
      case 'reports-good':
        return ReportsGood
      case 'reports-poor':
        return ReportsPoor
      case 'reports':
        return Reports
      case 'restart':
        return Restart
      case 'search':
        return Search
      case 'send-diagonal':
        return SendDiagonal
      case 'send-mail':
        return SendMail
      case 'send':
        return Send
      case 'server':
        return Server
      case 'settings':
        return Settings
      case 'share-ios':
        return ShareIos
      case 'smartphone-device':
        return SmartphoneDevice
      case 'sound-high':
        return SoundHigh
      case 'sound-off':
        return SoundOff
      case 'sphere':
        return Sphere
      case 'srns-agenda-solid':
        return SerenisAgendaSolid
      case 'srns-agenda':
        return SerenisAgenda
      case 'srns-black-lives-matter':
        return SerenisBlackLivesMatter
      case 'srns-sidebar-collapse':
        return SerenisSidebarCollapse
      case 'srns-sidebar-expand':
        return SerenisSidebarExpand
      case 'srns-smartphone':
        return SerenisSmartphone
      case 'srns-calendar':
        return SerenisCalendar
      case 'srns-calendar-plus':
        return SerenisCalendarPlus
      case 'srns-calendar-solid':
        return SerenisCalendarSolid
      case 'srns-chat-solid':
        return SerenisChatSolid
      case 'srns-chat':
        return SerenisChat
      case 'srns-check':
        return SerenisCheck
      case 'srns-check-circle':
        return SerenisCheckCircle
      case 'srns-clock':
        return SerenisClock
      case 'srns-close':
        return SerenisClose
      case 'srns-code':
        return SerenisCode
      case 'srns-community':
        return SerenisCommunity
      case 'srns-contact-phonebook':
        return SerenisContactPhonebook
      case 'srns-deadline':
        return SerenisDeadline
      case 'srns-generic':
        return SerenisGeneric
      case 'srns-home-solid':
        return SerenisHomeSolid
      case 'srns-home':
        return SerenisHome
      case 'srns-journaling-solid':
        return SerenisJournalingSolid
      case 'srns-journaling':
        return SerenisJournaling
      case 'srns-left':
        return SerenisLeft
      case 'srns-link':
        return SerenisLink
      case 'srns-member-get-member':
        return SerenisMemberGetMember
      case 'srns-menu-solid':
        return SerenisMenuSolid
      case 'srns-menu':
        return SerenisMenu
      case 'srns-money-box':
        return SerenisMoneyBox
      case 'srns-off':
        return SerenisOff
      case 'srns-question':
        return SerenisQuestion
      case 'srns-patients-solid':
        return SerenisPatientsSolid
      case 'srns-patients':
        return SerenisPatients
      case 'srns-right':
        return SerenisRight
      case 'srns-ringing-bell':
        return SerenisRingingBell
      case 'srns-shipping-box':
        return SerenisShippingBox
      case 'srns-sticker':
        return SerenisSticker
      case 'srns-sticker-square':
        return SerenisStickerSquare
      case 'srns-support':
        return SerenisSupport
      case 'srns-therapy-solid':
        return SerenisTherapySolid
      case 'srns-therapy':
        return SerenisTherapy
      case 'srns-ticket':
        return SerenisTicket
      case 'srns-xmark-circle':
        return SerenisXMarkCircle
      case 'star':
        return Star
      case 'star-solid':
        return StarSolid
      case 'stats-up-square':
        return StatsUpSquare
      case 'three-stars':
        return ThreeStars
      case 'timer-off':
        return TimerOff
      case 'timer':
        return Timer
      case 'transition-left':
        return TransitionLeft
      case 'transition-right':
        return TransitionRight
      case 'trash':
        return Trash
      case 'twitter':
        return Twitter
      case 'user-badge-check':
        return UserBadgeCheck
      case 'user-circle':
        return UserCircle
      case 'user-love':
        return UserLove
      case 'user-scan':
        return UserScan
      case 'user-star':
        return UserStar
      case 'user-xmark':
        return UserXmark
      case 'user':
        return User
      case 'video-camera-off':
        return VideoCameraOff
      case 'video-camera':
        return VideoCamera
      case 'view-structure-up':
        return ViewStructureUp
      case 'visa':
        return Visa
      case 'wallet':
        return Wallet
      case 'warning-circle-solid':
        return WarningCircleSolid
      case 'warning-circle':
        return WarningCircle
      case 'whatsapp':
        return Whatsapp
      case 'xmark-circle':
        return XmarkCircle
      case 'xmark':
        return Xmark
      default:
        return assertNever(name)
    }
  }, [name])

  if (!Component) {
    return null
  }

  return (
    <Component
      color={colorName ? cssvarColor(colorName) : 'currentColor'}
      data-test-id={`icon-${name}`}
      fill={fillColorName ? cssvarColor(fillColorName) : 'none'}
      height={size}
      width={size}
    />
  )
}

export const isIconName = (value: string): value is IconName => iconNames.includes(value as IconName)
