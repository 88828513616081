import { Flex } from 'cdk'
import { BORDER_RADIUS_4XS, cssvarColor, OPACITY_100, OPACITY_48 } from 'design-tokens'
import styled, { css } from 'styled-components'

export type SkeletonProps = {
  $active?: boolean
}

export const Skeleton = styled(Flex).attrs((attrs) => ({
  'aria-hidden': true,
  $borderRadius: BORDER_RADIUS_4XS,
  ...attrs,
}))<SkeletonProps>`
  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0 50%;
    }
  }

  margin: 0;
  ${({ $active, $backgroundColorName }) => {
    const color = $backgroundColorName ? cssvarColor($backgroundColorName) : 'currentColor'
    if ($active) {
      return css`
        background: linear-gradient(
          90deg,
          rgb(from ${color} r g b / ${OPACITY_48}) 25%,
          rgb(from ${color} r g b / ${OPACITY_100}) 37%,
          rgb(from ${color} r g b / ${OPACITY_48}) 63%
        );
        background-size: 400% 100%;
        animation-name: skeleton-loading;
        animation-duration: 1.4s;
        animation-timing-function: ease;
        animation-iteration-count: infinite;
      `
    }
    return css`
      background-color: ${color};
    `
  }}

  ${({ $minHeight }) =>
    !$minHeight &&
    css`
      height: 1em;
    `};
`
