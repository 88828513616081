/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const OPACITY_0 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-0)` as const
export const OPACITY_0_VALUE = '0'

/** `8%` */
export const OPACITY_8 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-8)` as const
export const OPACITY_8_VALUE = '8%'

/** `16%` */
export const OPACITY_16 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-16)` as const
export const OPACITY_16_VALUE = '16%'

/** `24%` */
export const OPACITY_24 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-24)` as const
export const OPACITY_24_VALUE = '24%'

/** `32%` */
export const OPACITY_32 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-32)` as const
export const OPACITY_32_VALUE = '32%'

/** `40%` */
export const OPACITY_40 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-40)` as const
export const OPACITY_40_VALUE = '40%'

/** `48%` */
export const OPACITY_48 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-48)` as const
export const OPACITY_48_VALUE = '48%'

/** `56%` */
export const OPACITY_56 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-56)` as const
export const OPACITY_56_VALUE = '56%'

/** `64%` */
export const OPACITY_64 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-64)` as const
export const OPACITY_64_VALUE = '64%'

/** `72%` */
export const OPACITY_72 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-72)` as const
export const OPACITY_72_VALUE = '72%'

/** `80%` */
export const OPACITY_80 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-80)` as const
export const OPACITY_80_VALUE = '80%'

/** `88%` */
export const OPACITY_88 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-88)` as const
export const OPACITY_88_VALUE = '88%'

/** `96%` */
export const OPACITY_96 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-96)` as const
export const OPACITY_96_VALUE = '96%'

/** `1` */
export const OPACITY_100 = `var(--${DESIGN_TOKENS_PREFIX}-opacity-100)` as const
export const OPACITY_100_VALUE = '1'
