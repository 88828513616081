import { useRouter } from 'next/router'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const useConventionCodeName = () => {
  const { query } = useRouter()

  const conventionCodeName = getLocalStorage('convention-code-name')

  if (conventionCodeName) {
    return conventionCodeName
  }

  const { cnv } = query

  if (!cnv) {
    return null
  }

  const name = String(cnv)

  setLocalStorage('convention-code-name', name)

  return name
}
