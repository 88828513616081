import { Hub, type HubCapsule } from '@aws-amplify/core'
import { noop } from 'functions'
import { useRouter } from 'next/router'
import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Apollo } from '~/clients/apollo'
import { Chat } from '~/clients/chat'
import { useReactNativePostMessage } from '~/domains/appNative/hooks/useReactNativePostMessage'
import { useIntercom } from '~/domains/intercom/hooks/useIntercom'
import { useCurrentUserNullable } from '~/hooks/useCurrentUser'
import { useToasts } from '~/hooks/useToasts'
import { useAuthConfigureEffect } from '../hooks/useAuthConfigureEffect'
import { useAuthMethods } from '../hooks/useAuthMethods'
import { type AccessTokenPayload } from '../types/accessTokenPayload'
import { type CognitoUser } from '../types/cognitoUser'
import { type IdTokenPayload } from '../types/idTokenPayload'
import { toAccessTokenPayload } from '../utils/toAccessTokenPayload'
import { toIdTokenJwt } from '../utils/toIdTokenJwt'
import { toIdTokenPayload } from '../utils/toIdTokenPayload'

type Context = {
  accessTokenPayload: AccessTokenPayload | null
  idTokenJwt: string | null
  idTokenPayload: IdTokenPayload | null
  isAuthenticated: boolean
  isAuthenticating: boolean
}

const AuthStateContext = createContext<Context | null>(null)

type Props = {
  children: ReactNode
}

export const AuthStateProvider = ({ children }: Props) => {
  const { onLoginCompleted, onLogoutCompleted, id: currentUserId } = useCurrentUserNullable()
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [configured, setConfigured] = useState(false)
  const [user, setUser] = useState<CognitoUser | null>(null)
  const { addToast } = useToasts()
  const { currentAuthenticatedUser, signOut } = useAuthMethods()
  const router = useRouter()
  const { dismissAuthSession, sendUserIdentity } = useReactNativePostMessage()
  const { logout } = useIntercom()
  const { redirectTo } = router.query

  useAuthConfigureEffect()

  const handleConfigurationCompleted = useCallback(() => {
    setIsAuthenticating(false)

    dismissAuthSession()
  }, [dismissAuthSession])

  const handleExternalSaasTherapistLogout = useCallback(
    (user: CognitoUser) => {
      try {
        const cognitoGroups = user.signInUserSession.accessToken.payload['cognito:groups']

        const isTherapist = cognitoGroups.includes('therapist')

        const isSaasTherapist = cognitoGroups.includes('saas-therapist')

        const isSaasTherapistExternal = isSaasTherapist && !isTherapist

        if (isSaasTherapistExternal) {
          addToast({ translationId: 'generic.error.unauthorizedAccess', type: 'alert' })

          signOut()
          setUser(null)
          setIsAuthenticating(false)
          onLogoutCompleted()
        }
        // eslint-disable-next-line no-empty
      } catch {}
    },
    [addToast, onLogoutCompleted, signOut],
  )

  const hubCallback = useCallback(
    async (hubCapsule: HubCapsule) => {
      const {
        payload: { event, data },
      } = hubCapsule

      const sendIdentityToNative = (user: CognitoUser) =>
        sendUserIdentity({ id: user?.attributes?.['custom:user_id'] as string, email: user.attributes?.email })

      switch (event) {
        case 'configured': {
          if (configured) {
            return
          }

          setConfigured(true)

          currentAuthenticatedUser()
            .then((user) => {
              setUser(user)
              onLoginCompleted()

              handleExternalSaasTherapistLogout(user)
              sendIdentityToNative(user)
            })
            .catch(noop)
            .finally(handleConfigurationCompleted)

          return
        }
        case 'signIn': {
          const user = data as CognitoUser

          setUser(user)
          sendIdentityToNative(user)

          handleConfigurationCompleted()
          onLoginCompleted()

          handleExternalSaasTherapistLogout(user)

          if (typeof redirectTo === 'string') {
            router.replace(redirectTo)

            return
          }

          return
        }
        case 'signOut': {
          await Apollo.clearStore()
          await Chat.disconnectUser()
          logout()
          setUser(null)
          setIsAuthenticating(false)
          onLogoutCompleted()

          return
        }
        case 'tokenRefresh': {
          setIsAuthenticating(true)

          currentAuthenticatedUser()
            .then((user) => {
              setUser(user)
              sendIdentityToNative(user)
            })
            .catch(noop)
            .finally(handleConfigurationCompleted)

          return
        }
      }
    },
    [
      configured,
      currentAuthenticatedUser,
      handleConfigurationCompleted,
      handleExternalSaasTherapistLogout,
      logout,
      onLoginCompleted,
      onLogoutCompleted,
      router,
      redirectTo,
      sendUserIdentity,
    ],
  )

  Hub.listen('auth', hubCallback)

  useEffect(() => () => Hub.remove('auth', hubCallback), [hubCallback])

  const value = useMemo(
    (): Context => ({
      accessTokenPayload: user?.signInUserSession ? toAccessTokenPayload(user) : null,
      idTokenPayload: user?.signInUserSession ? toIdTokenPayload(user) : null,
      idTokenJwt: user?.signInUserSession ? toIdTokenJwt(user) : null,
      isAuthenticated: !isAuthenticating && !!user && !!currentUserId,
      isAuthenticating,
    }),
    [currentUserId, isAuthenticating, user],
  )

  return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>
}

export const useAuthState = (): Context => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('The `useAuthState` should be wrapped with `AuthStateProvider`.')
  }

  return context
}
