import { getParser } from 'bowser'

function compareVersions(v1: string, v2: string): number {
  const parts1 = v1.split('.').map(Number)
  const parts2 = v2.split('.').map(Number)

  return parts1.map((num, i) => num - (parts2[i] ?? 0)).find((diff) => diff !== 0) ?? 0
}

const browser = typeof window !== 'undefined' ? getParser(window.navigator.userAgent) : null

export const browserVersionSupported = (() => {
  if (browser == null) {
    return false
  }

  const satisfied = browser.satisfies({
    chrome: '>=100', // March 29th 2022
    firefox: '>=100', // May 3rd 2022
    opera: '>=80', // October 5th 2021
    safari: '>=15', // September 20th 2021
    edge: '>=100', // 1st April 2022
  })

  return satisfied != null && satisfied
})()

export const browserSupportsVideocall = (() => {
  if (browser == null) {
    return false
  }

  const satisfied = browser.satisfies({
    chrome: '>=91', // May 25th 2021
    firefox: '>=89', // June 1st 2021
    safari: '>=15', // September 20th 2021
    edge: '>=91', // 27th May 2021
  })

  if (satisfied != null && satisfied) {
    return true
  }

  const os = browser.getOS()

  if (os.name === 'iOS') {
    // On iOS the Safari version usually matches with the OS version, use it as fallback
    return os.version != null && compareVersions(os.version, '15') >= 0
  }

  if (os.name === 'Android') {
    // For apps that use WebView, Chrome's WebView engine is the default choice for displaying web pages,
    // unless an app specifically chooses to use a different one.
    // Android 12 was released on October 2021, after Chrome 91.
    // So it's almost guaranteed that devices running Android 12 and above have a Chrome version >= 91
    // If a device has an updated version of Chrome, it's highly likely that WebView is running a similar version of Chromium.
    return os.version != null && compareVersions(os.version, '12') >= 0
  }

  return false
})()
