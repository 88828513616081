import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisSmartphone = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.8738 5h4.2524M5.17965 19.4966c.06921 1.2168.72097 2.1479 1.57274 2.2467C7.82847 21.8682 9.54533 22 12 22c2.4547 0 4.1715-.1318 5.2476-.2567.8518-.0988 1.5035-1.0299 1.5727-2.2467C18.9078 17.9593 19 15.5067 19 12c0-1.7595-.0232-3.25369-.0574-4.5-.034-1.23753-.0787-2.23067-.1223-2.99658-.0692-1.21681-.7209-2.14791-1.5727-2.24677C16.1715 2.13175 14.4547 2 12 2c-2.45467 0-4.17153.13175-5.24761.25665-.85176.09886-1.50353 1.02995-1.57273 2.24676C5.1361 5.26932 5.09134 6.26247 5.0574 7.5 5.02322 8.74631 5 10.2405 5 12c0 3.5067.09222 5.9593.17965 7.4966Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
