import {
  FloatingFocusManager,
  FloatingOverlay as FloatingUiFloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { Flex, media, PositionRelative, Pressable } from 'cdk'
import {
  BORDER_RADIUS_0,
  COLOR_BLACK,
  COLOR_LIGHTER,
  OPACITY_0,
  OPACITY_48,
  SPACING_0,
  SPACING_6XL_VALUE,
  SPACING_MD,
  TIME_300_VALUE,
} from 'design-tokens'
import { animate, AnimatePresence, motion, useDragControls, useMotionValue } from 'framer-motion'
import { useWindowSize } from 'hooks'
import { Icon } from 'icons'
import { toInt } from 'numbers'
import {
  type ButtonHTMLAttributes,
  cloneElement,
  createContext,
  forwardRef,
  type HTMLProps,
  isValidElement,
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { Card } from './Card'
import { Text, type TextProps } from './Text'

const MODAL_MARGIN = toInt(SPACING_6XL_VALUE)

export type ModalOptions = {
  initialIsOpen?: boolean
  isOpen?: boolean
  onIsOpenChange?: (isOpen: boolean) => void
  preventClosing?: boolean
  preventClosingOutside?: boolean
}

export function useModal({
  initialIsOpen = false,
  isOpen: controlledIsOpen,
  onIsOpenChange: setControlledIsOpen,
  preventClosing = false,
  preventClosingOutside = false,
}: ModalOptions) {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(initialIsOpen)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen
  const setIsOpen = setControlledIsOpen ?? setUncontrolledIsOpen

  const data = useFloating({ open: isOpen, onOpenChange: setIsOpen })
  const click = useClick(data.context, { enabled: controlledIsOpen == null })
  const dismiss = useDismiss(data.context, {
    enabled: !preventClosing,
    outsidePress: !preventClosingOutside,
    outsidePressEvent: 'mousedown',
  })
  const role = useRole(data.context)
  const interactions = useInteractions([click, dismiss, role])

  const close = useCallback(() => {
    if (!preventClosing) {
      setIsOpen(false)
    }
  }, [preventClosing, setIsOpen])

  const { isMounted, styles: modalContainerStyles } = useTransitionStyles(data.context, {
    duration: parseInt(TIME_300_VALUE),
    initial: { translate: '0 100%', filter: 'blur(3.5px)' },
  })
  const { styles: floatingOverlayStyles } = useTransitionStyles(data.context, {
    initial: { opacity: OPACITY_0 },
  })

  return useMemo(
    () => ({
      close,
      isOpen,
      preventClosing,
      preventClosingOutside,
      setIsOpen,
      ...interactions,
      ...data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      close,
      isOpen,
      preventClosing,
      preventClosingOutside,
      setIsOpen,
      interactions,
      data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
    ],
  )
}

type ContextType = ReturnType<typeof useModal> | null

const ModalContext = createContext<ContextType>(null)

export const useModalContext = () => {
  const context = useContext(ModalContext)

  if (context === null) {
    throw new Error('Modal components must be wrapped in <ModalProvider />')
  }

  return context
}

const ModalClose = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => {
  const { close } = useModalContext()

  return (
    <Pressable {...props} ref={ref} aria-label="close" data-test-id="modal-close" onClick={close}>
      <Flex>
        <Icon name="srns-close" size={24} />
      </Flex>
    </Pressable>
  )
})

type ModalTriggerProps = PropsWithChildren

export const ModalTrigger = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & ModalTriggerProps>(
  ({ children, ...props }, propRef) => {
    const { getReferenceProps, isOpen, refs } = useModalContext()
    const ref = useMergeRefs([refs.setReference, propRef, (children as any).ref])
    const otherProps = {
      'data-state': isOpen ? 'open' : 'closed',
    }

    if (isValidElement(children)) {
      return cloneElement(
        children,
        getReferenceProps({
          ref,
          ...props,
          ...children.props,
          ...otherProps,
        }),
      )
    }

    return (
      <Pressable ref={ref} {...otherProps} {...getReferenceProps(props)}>
        {children}
      </Pressable>
    )
  },
)

export const ModalTop = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps } = useModalContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    return (
      <Text as="p" kind="paragraph" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const ModalTitle = forwardRef<HTMLElement, TextProps & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setLabelId } = useModalContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <Text as="h2" kind="h1" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const ModalDescription = forwardRef<HTMLElement, TextProps & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setDescriptionId } = useModalContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setDescriptionId(id)

      return () => setDescriptionId(undefined)
    }, [id, setDescriptionId])

    return (
      <Text as="p" kind="paragraph" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const FloatingOverlay = styled(FloatingUiFloatingOverlay)`
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${media.gtSm`
    align-items: center;
    justify-content: center;
  `}

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: ${COLOR_BLACK};
    opacity: ${OPACITY_48};
  }
`

const ModalContainer = styled(Card)`
  ${media.sm`
    border-bottom-left-radius: ${BORDER_RADIUS_0};
    border-bottom-right-radius: ${BORDER_RADIUS_0};
  `}

  ${media.gtSm`
    inset: auto;
    width: 400px;
  `}
`

const ModalBottomContainer = styled(PositionRelative)`
  ${media.sm`
    &::before {
      content: '';
      position: absolute;
      inset: -1px 0 -999px;
      background-color: ${COLOR_LIGHTER};
    }
  `}
`

export type ModalProps = PropsWithChildren

export const Modal = forwardRef<HTMLElement, ModalProps & HTMLProps<HTMLElement>>(({ children, ...props }, propRef) => {
  const {
    close,
    context,
    descriptionId,
    floatingOverlayStyles,
    getFloatingProps,
    isMounted,
    labelId,
    modalContainerStyles,
    preventClosing,
    refs,
  } = useModalContext()
  const { height = 0 } = useWindowSize()
  const ref = useMergeRefs([refs.setFloating, propRef])
  const modalContainerHeight = height - MODAL_MARGIN
  const y = useMotionValue(modalContainerHeight)
  const controls = useDragControls()

  if (!isMounted) {
    return null
  }

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll style={floatingOverlayStyles}>
        <FloatingFocusManager context={context}>
          <AnimatePresence>
            <ModalContainer
              ref={ref}
              $bottom={0}
              $elevationName="lg"
              $left={0}
              $maxHeight={`calc(100% - ${MODAL_MARGIN}px)`}
              $p={SPACING_0}
              $position="fixed"
              $right={0}
              $zIndex={102}
              animate={{ y: 0 }}
              aria-describedby={descriptionId}
              aria-labelledby={labelId}
              aria-modal="true"
              as={motion.div}
              drag="y"
              dragConstraints={{ ...(preventClosing && { bottom: 0 }), top: 0 }}
              dragControls={controls}
              dragElastic={0.1}
              dragListener={false}
              exit={{ y: modalContainerHeight }}
              initial={{ y: modalContainerHeight }}
              onDragEnd={(_, { offset, velocity }) => {
                if (offset.y > height * 0.75 || velocity.y > 10) {
                  close()
                } else {
                  animate(y, 0, {
                    type: 'inertia',
                    bounceStiffness: 300,
                    bounceDamping: 40,
                    timeConstant: 300,
                    min: 0,
                    max: 0,
                  })
                }
              }}
              style={modalContainerStyles}
              transition={{ duration: 0.5, ease: [0.32, 0.72, 0, 1] }}
              {...getFloatingProps(props)}
            >
              <Flex
                $align="flex-end"
                $p={SPACING_MD}
                onPointerDown={(event) => controls.start(event)}
                style={{ touchAction: 'none' }}
              >
                {!preventClosing && <ModalClose />}
              </Flex>
              <PositionRelative
                $grow={1}
                $pb={SPACING_MD}
                $px={SPACING_MD}
                $shrink={1}
                onPointerDownCapture={(event) => event.stopPropagation()}
              >
                {children}
              </PositionRelative>
              <ModalBottomContainer />
            </ModalContainer>
          </AnimatePresence>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export type ModalProviderProps = PropsWithChildren<
  {
    children: [ReactElement<typeof ModalTrigger>, ReactElement<typeof Modal>] | ReactElement<typeof Modal>
  } & ModalOptions
>

export const ModalProvider = ({ children, ...options }: ModalProviderProps) => {
  const modal = useModal(options)

  return <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
}
