import { SPACING_XS } from 'design-tokens'
import { type AllHTMLAttributes, type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Card } from './Card'

const TileChildrenContainer = styled.div`
  flex: 1;
`

export type TileProps = PropsWithChildren<{
  endChildren?: PropsWithChildren['children']
  startChildren?: PropsWithChildren['children']
}> &
  AllHTMLAttributes<HTMLElement>

export const Tile = ({ children, endChildren, startChildren, ...htmlProps }: TileProps) => (
  <Card $align="center" $direction="row" $gap={SPACING_XS} {...htmlProps}>
    {startChildren}
    <TileChildrenContainer>{children}</TileChildrenContainer>
    {endChildren}
  </Card>
)
