import { useRouter } from 'next/router'
import { getLocalStorage } from '~/utils/localStorage/getLocalStorage'
import { setLocalStorage } from '~/utils/localStorage/setLocalStorage'

export const useReferralCodeName = () => {
  const { query } = useRouter()

  const referralCodeName = getLocalStorage('referral-code-name')

  if (referralCodeName) {
    return referralCodeName
  }

  const { rfrrl } = query

  if (!rfrrl) {
    return null
  }

  const name = String(rfrrl)

  setLocalStorage('referral-code-name', name)

  return name
}
