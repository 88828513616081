import { BORDER_RADIUS_XS, type ColorName, SPACING_XS } from 'design-tokens'
import { type AriaAttributes, type PropsWithChildren, useMemo } from 'react'
import styled from 'styled-components'
import { uidWithPrefix } from 'uid'
import { Card } from './Card'
import { Text } from './Text'

type Kind = 'error' | 'warning'

export const alertKindsColors: Record<
  Kind,
  {
    background: ColorName
  }
> = {
  error: { background: 'red-20' },
  warning: { background: 'yellow-20' },
} as const

const EndChildrenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export type AlertProps =
  | {
      children?: never
      description: string
      endChildren: PropsWithChildren['children']
      kind: Kind
      startChildren: PropsWithChildren['children']
      title?: string
    }
  | PropsWithChildren<{
      description?: never
      endChildren: PropsWithChildren['children']
      kind: Kind
      startChildren: PropsWithChildren['children']
      title?: never
    }>

const AlertChildrenContainer = styled.div`
  flex: 1;
`

export const Alert = ({ children, description, endChildren, kind, startChildren, title }: AlertProps) => {
  const uuid = useMemo(() => uidWithPrefix('alert'), [])

  const ariaAttrs: AriaAttributes = useMemo(
    () => ({
      'aria-describedby': `description_${uuid}`,
      ...(title && { 'aria-labelledby': `title_${uuid}` }),
    }),
    [title, uuid],
  )

  return (
    <Card
      $backgroundColorName={alertKindsColors[kind].background}
      $borderRadius={BORDER_RADIUS_XS}
      $direction="row"
      $elevationName="0"
      $gap={SPACING_XS}
      $overflow="hidden"
      $p={SPACING_XS}
      $position="relative"
      {...(!children && ariaAttrs)}
      as="div"
      role="alert"
    >
      {startChildren}
      <AlertChildrenContainer>
        {children ? (
          <div>{children}</div>
        ) : (
          <>
            {title && (
              <Text as="p" fontWeight="600" id={`title_${uuid}`} kind="caption">
                {title}
              </Text>
            )}
            <Text as="p" id={`description_${uuid}`} kind="caption">
              {description}
            </Text>
          </>
        )}
      </AlertChildrenContainer>
      <EndChildrenContainer>{endChildren}</EndChildrenContainer>
    </Card>
  )
}
