import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisDeadline = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#dead-line-clip-path)">
      <g clipPath="url(#dead-line-clip-path-2)" stroke={color} strokeLinecap="round" strokeWidth="1.4">
        <path
          d="M1.5861 8.5c.05092-1.23753.11804-2.23068.18338-2.99659.10381-1.21681 1.08146-2.1479 2.35911-2.24676C5.7427 3.13175 8.318 3 12 3c3.682 0 6.2573.13175 7.8714.25665 1.2777.09886 2.2553 1.02996 2.3591 2.24677.0653.76591.1325 1.75905.1834 2.99658m-20.8278 0C1.53483 9.74631 1.5 11.2405 1.5 13c0 3.5067.13833 5.9593.26948 7.4966.1038 1.2168 1.08145 2.1479 2.3591 2.2467C5.7427 22.8682 8.318 23 12 23c3.682 0 6.2573-.1318 7.8714-.2567 1.2777-.0988 2.2553-1.0299 2.3591-2.2467.1311-1.5373.2695-3.9899.2695-7.4966 0-1.7595-.0348-3.25369-.0861-4.5m-20.8278 0h20.8278M7.5 5.50342V1m9 4.50342V1"
          strokeLinejoin="round"
        />
        <path d="m9 12 6 6m0-6-6 6" />
      </g>
    </g>
    <defs>
      <clipPath id="dead-line-clip-path">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
      <clipPath id="dead-line-clip-path-2">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)
