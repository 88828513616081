import { gql, useMutation } from '@apollo/client'
import { type LinkFormFlowResponsesMutation, type LinkFormFlowResponsesMutationVariables } from '~/types/graphql'

const MUTATION = gql`
  mutation linkFormFlowResponses {
    linkFormFlowResponses {
      ok
    }
  }
`

export const useLinkFormFlowResponses = () =>
  useMutation<LinkFormFlowResponsesMutation, LinkFormFlowResponsesMutationVariables>(MUTATION)
