/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `10px` */
export const FONT_SIZE_10 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-10)` as const
export const FONT_SIZE_10_VALUE = '10px'

/** `12px` */
export const FONT_SIZE_12 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-12)` as const
export const FONT_SIZE_12_VALUE = '12px'

/** `14px` */
export const FONT_SIZE_14 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-14)` as const
export const FONT_SIZE_14_VALUE = '14px'

/** `16px` */
export const FONT_SIZE_16 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-16)` as const
export const FONT_SIZE_16_VALUE = '16px'

/** `18px` */
export const FONT_SIZE_18 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-18)` as const
export const FONT_SIZE_18_VALUE = '18px'

/** `20px` */
export const FONT_SIZE_20 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-20)` as const
export const FONT_SIZE_20_VALUE = '20px'

/** `24px` */
export const FONT_SIZE_24 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-24)` as const
export const FONT_SIZE_24_VALUE = '24px'

/** `32px` */
export const FONT_SIZE_32 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-32)` as const
export const FONT_SIZE_32_VALUE = '32px'

/** `40px` */
export const FONT_SIZE_40 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-40)` as const
export const FONT_SIZE_40_VALUE = '40px'

/** `48px` */
export const FONT_SIZE_48 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-48)` as const
export const FONT_SIZE_48_VALUE = '48px'

/** `56px` */
export const FONT_SIZE_56 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-56)` as const
export const FONT_SIZE_56_VALUE = '56px'

/** `64px` */
export const FONT_SIZE_64 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-64)` as const
export const FONT_SIZE_64_VALUE = '64px'

/** `72px` */
export const FONT_SIZE_72 = `var(--${DESIGN_TOKENS_PREFIX}-font-size-72)` as const
export const FONT_SIZE_72_VALUE = '72px'
