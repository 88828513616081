import { ApolloProvider } from '@apollo/client'
import * as Sentry from '@sentry/nextjs'
import { media } from 'cdk'
import 'design-tokens/css'
import { type AppProps } from 'next/app'
import NextHead from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'react-medium-image-zoom/dist/styles.css'
import 'stream-chat-react/dist/scss/v2/index.scss'
import styled, { createGlobalStyle, StyleSheetManager } from 'styled-components'
import { GlobalStyle as UiGlobalStyle, shouldForwardProp } from 'ui'
import { Flex } from 'ui-deprecated'
import { Apollo } from '~/clients/apollo'
import { Toasts } from '~/components/Toasts'
import { useClarityIdentifyEffect } from '~/domains/analytics/hooks/useClarityIdentifyEffect'
import { ThirdPartyAppProvider } from '~/domains/appNative/hooks/useThirdPartyApp'
import { AuthStateProvider } from '~/domains/auth/components/AuthStateProvider'
import { useConventionCodeName } from '~/domains/convention/hooks/useConventionCodeName'
import { AssertMinimumRequirements } from '~/domains/error/AssertMinimumRequirements'
import { GenericError } from '~/domains/error/GenericError'
import { IntercomProvider } from '~/domains/intercom/components/IntercomProvider'
import { useUpdateIntercom } from '~/domains/intercom/hooks/useUpdateIntercom'
import { useUpdateIntercomFingerprint } from '~/domains/intercom/hooks/useUpdateIntercomFingerprint'
import { useReferralCodeName } from '~/domains/referral/hooks/useReferralCodeName'
import { useAddUserInformationToDataLayer } from '~/hooks/useAddUserInformationToDataLayer'
import { useAddUserToSentryEffect } from '~/hooks/useAddUserToSentryEffect'
import { CurrentUserProvider } from '~/hooks/useCurrentUser'
import { useInitializeDataLayer } from '~/hooks/useInitializeDataLayer'
import { LoadingStateProvider } from '~/hooks/useLoadingState'
import { useQueryParamInSessionStorageEffect } from '~/hooks/useQueryParamInSessionStorageEffect'
import { useSetupAnonymousFingerprintEffect } from '~/hooks/useSetupAnonymousFingerprintEffect'
import { ToastsProvider } from '~/hooks/useToasts'
import { useUpdateLocalStorageSettingsInvite } from '~/hooks/useUpdateLocalStorageSettingsInvite'
import { LanguageProvider } from '~/i18n/hooks/useLanguage'
import { getEnv } from '~/utils/getEnv'

const Wrapper = styled(Flex).attrs({
  grow: 1,
})`
  height: 100%;
  /* stylelint-disable-next-line declaration-property-value-no-unknown */
  max-height: fill-available;
`

const AppGlobalStyle = createGlobalStyle`
  /* stylelint-disable selector-id-pattern */
  html, body, #__next {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    /* stylelint-disable-next-line declaration-property-value-no-unknown */
    height: fill-available;
    height: 100%;
    /* stylelint-disable-next-line declaration-property-value-no-unknown */
    max-height: fill-available;
  }

  body, #__next {
    flex-grow: 1;
  }

  ${media.sm`
    .intercom-namespace.intercom-namespace {
      .intercom-app > [class^="intercom-with-namespace"] {
        bottom: 98px;
      }
    }
  `}
`

const SetupEffect = () => {
  useAddUserInformationToDataLayer()
  useAddUserToSentryEffect()
  useClarityIdentifyEffect()
  useUpdateLocalStorageSettingsInvite()
  useUpdateIntercom()
  useUpdateIntercomFingerprint()
  useReferralCodeName()
  useConventionCodeName()

  return null
}

const App = ({ Component, pageProps }: AppProps) => {
  useInitializeDataLayer()
  useSetupAnonymousFingerprintEffect()
  useQueryParamInSessionStorageEffect()

  const [domLoaded, setDomLoaded] = useState(false)
  const { isReady } = useRouter()

  useEffect(() => {
    setDomLoaded(true)
  }, [])

  if (!domLoaded || !isReady) {
    return null
  }

  return (
    <>
      <NextHead>
        <meta key="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" name="viewport" />
      </NextHead>

      <Script id="gtm" strategy="afterInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://gtm.serenis.it/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${getEnv('GTM_ID')}');`}
      </Script>

      <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
        <>
          <UiGlobalStyle />

          <AppGlobalStyle />

          <Sentry.ErrorBoundary fallback={<GenericError />}>
            <AssertMinimumRequirements>
              <ApolloProvider client={Apollo}>
                <LanguageProvider>
                  <IntercomProvider>
                    <LoadingStateProvider>
                      <ToastsProvider>
                        <ThirdPartyAppProvider>
                          <CurrentUserProvider>
                            <AuthStateProvider>
                              <SetupEffect />

                              <Wrapper>
                                <Component {...pageProps} />

                                <Toasts />
                              </Wrapper>
                            </AuthStateProvider>
                          </CurrentUserProvider>
                        </ThirdPartyAppProvider>
                      </ToastsProvider>
                    </LoadingStateProvider>
                  </IntercomProvider>
                </LanguageProvider>
              </ApolloProvider>
            </AssertMinimumRequirements>
          </Sentry.ErrorBoundary>
        </>
      </StyleSheetManager>
    </>
  )
}

export default App
