import { cssvarName } from 'design-tokens'
import { css } from 'styled-components'

type State = 'default' | 'hover' | 'active'

type CSSVariableMapValue = string | Record<State, string>

export type CSSVariableMap<T extends string, K extends string> = Record<T, Record<K, CSSVariableMapValue>>

export const generateCSSVariables = <T extends string, K extends string>(
  cssVariableMap: CSSVariableMap<T, K>,
  kind: K,
  state: State,
) => css`
  ${Object.entries(cssVariableMap).map(([name, kinds]) => {
    const typedKinds = kinds as Record<K, CSSVariableMapValue>

    return css`
      ${cssvarName(name)}: ${typeof typedKinds[kind] === 'string' ? typedKinds[kind] : typedKinds[kind][state]};
    `
  })}
`
