import { Flex, type FlexProps } from 'cdk'
import {
  BORDER_RADIUS_SM,
  BORDER_WIDTH_1,
  COLOR_NEUTRAL_70,
  COLOR_PRIMARY,
  COLOR_TRANSPARENT,
  cssvar,
  cssvarElevation,
  cssvarName,
  cssvarValue,
  ELEVATION_0,
  ELEVATION_SPECIAL,
  ELEVATION_XS,
  ELEVATION_XXS,
  OPACITY_32,
  SPACING_MD,
  TIME_150,
} from 'design-tokens'
import styled, { css } from 'styled-components'
import { type CSSVariableMap, generateCSSVariables } from './utils/generateCSSVariables'

export const CARD_KINDS = ['default', 'banner', 'placeholder'] as const

export type CardKind = (typeof CARD_KINDS)[number]

export const DESIGN_TOKENS_CARD_PREFIX = 'card'

export const cardCssVariables = ['box-shadow', 'outline-color', 'outline-style'] as const

export type CardCssVariableName = (typeof cardCssVariables)[number]

export type CardCSSVariable = `${typeof DESIGN_TOKENS_CARD_PREFIX}-${CardCssVariableName}`

export const cssvarNameCard = (variable: CardCssVariableName) => cssvarName(`${DESIGN_TOKENS_CARD_PREFIX}-${variable}`)

export const cssvarValueCard = (variable: CardCssVariableName) =>
  cssvarValue(`${DESIGN_TOKENS_CARD_PREFIX}-${variable}`)

export const cssvarCard = (variable: CardCssVariableName) => cssvar(`${DESIGN_TOKENS_CARD_PREFIX}-${variable}`)

const CARD_KIND_CSS_VARIABLES: CSSVariableMap<CardCSSVariable, CardKind> = {
  'card-box-shadow': {
    default: { default: ELEVATION_XXS, hover: ELEVATION_XS, active: ELEVATION_XXS },
    banner: { default: ELEVATION_SPECIAL, hover: ELEVATION_SPECIAL, active: ELEVATION_SPECIAL },
    placeholder: ELEVATION_0,
  },
  'card-outline-style': { default: 'solid', banner: 'solid', placeholder: 'dashed' },
  'card-outline-color': {
    default: {
      default: COLOR_TRANSPARENT,
      hover: `rgb(from ${COLOR_PRIMARY} r g b / ${OPACITY_32})`,
      active: COLOR_PRIMARY,
    },
    banner: {
      default: COLOR_TRANSPARENT,
      hover: COLOR_TRANSPARENT,
      active: COLOR_PRIMARY,
    },
    placeholder: { default: COLOR_NEUTRAL_70, hover: COLOR_PRIMARY, active: COLOR_PRIMARY },
  },
}

export type CardProps = FlexProps & {
  $isSelected?: boolean
  $kind?: CardKind
}

export const Card = styled(Flex)
  .withConfig({ displayName: 'Card' })
  .attrs<CardProps>((attrs) => ({
    $backgroundColorName: 'lighter',
    $borderRadius: BORDER_RADIUS_SM,
    $p: SPACING_MD,
    ...attrs,
  }))`
  ${({ $isSelected = false, $kind = 'default', $elevationName }) => css`
    ${generateCSSVariables(CARD_KIND_CSS_VARIABLES, $kind, 'default')}

    box-shadow: ${$elevationName ? cssvarElevation($elevationName) : cssvarCard('box-shadow')};
    outline: ${BORDER_WIDTH_1} ${cssvarCard('outline-style')} ${cssvarCard('outline-color')};
    transition:
      background-color ${TIME_150} ease-in-out,
      border-color ${TIME_150} ease-in-out,
      box-shadow ${TIME_150} ease-in-out,
      outline-color ${TIME_150} ease-in-out;

    a&:hover,
    a&:focus,
    button&:hover,
    button&:focus,
    a:hover &,
    a:focus &,
    button:hover &,
    button:focus & {
      ${!$isSelected && generateCSSVariables(CARD_KIND_CSS_VARIABLES, $kind, 'hover')}
    }

    a&:active,
    a:active &,
    button&:active,
    button:active & ${$isSelected && ', &'} {
      ${generateCSSVariables(CARD_KIND_CSS_VARIABLES, $kind, 'active')}
    }
  `}
`
