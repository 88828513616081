import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisCode = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#code-clip-path)" stroke={color} strokeLinejoin="round" strokeWidth="1.4">
      <path d="M6.70146 21.8524c-1.03168.1968-1.98828-.3221-2.35182-1.3081-.27873-.764-.53694-1.5354-.77435-2.3134-.12782-.4155.06426-.853.37113-1.1608.34992-.3496.6019-.785.7306-1.2626.12869-.4776.12957-.9804.00254-1.4579-.12763-.4773-.37859-.9122-.72765-1.2611-.34906-.3489-.78392-.5995-1.26085-.7265-.41905-.113-.80308-.3954-.89902-.819-.22769-1.009-.37954-1.79361-.48122-2.39377-.17562-1.03607.39469-1.96592 1.38606-2.31394 1.32222-.46382 3.62361-1.19746 7.45542-2.22863 3.8318-1.03117 6.1899-1.55194 7.5655-1.81365 1.0317-.19688 1.9883.32206 2.3518 1.30805.2105.5704.4689 1.32636.7736 2.31408.1283.41544-.0644.85256-.3711 1.16082-.3497.34968-.6015.78516-.73 1.26267-.1285.47751-.1293.98022-.0023 1.45757.1277.4774.3786.9124.7277 1.2614.3491.349.784.5996 1.261.7267.4189.1125.8029.3949.8989.8186.1817.7934.3422 1.5916.4812 2.3937.1756 1.0361-.3947 1.9659-1.3861 2.3139-1.3217.4637-3.6237 1.197-7.455 2.2281-3.8318 1.0312-6.19042 1.552-7.56604 1.8138Z" />
      <path
        d="m16.6794 17.4497.4549 1.7032m-1.6675-6.2451.4851 1.8168m-1.6982-6.35863.4851 1.81673M13.071 3.93765l.4549 1.7032"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="code-clip-path">
        <path d="M0 0h24v24H0z" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
)
