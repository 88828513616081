/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { cssvarValue, DESIGN_TOKENS_PREFIX } from '../utils'

/** In `light` theme is `#CAFACB` */
export const COLOR_MINTTEA_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-20)` as const
export const COLOR_MINTTEA_20_VALUE = cssvarValue('color-minttea-20')

/** In `light` theme is `#179A92` */
export const COLOR_MINTTEA_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-40)` as const
export const COLOR_MINTTEA_40_VALUE = cssvarValue('color-minttea-40')

/** In `light` theme is `#003B2E` */
export const COLOR_MINTTEA_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-60)` as const
export const COLOR_MINTTEA_60_VALUE = cssvarValue('color-minttea-60')

/** In `light` theme is `#061A1D` */
export const COLOR_MINTTEA_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea-80)` as const
export const COLOR_MINTTEA_80_VALUE = cssvarValue('color-minttea-80')

/** In `light` theme is `#F1D6FF` */
export const COLOR_MENTAL_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-mental-20)` as const
export const COLOR_MENTAL_20_VALUE = cssvarValue('color-mental-20')

/** In `light` theme is `#CE8EF2` */
export const COLOR_MENTAL_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-mental-40)` as const
export const COLOR_MENTAL_40_VALUE = cssvarValue('color-mental-40')

/** In `light` theme is `#AE4FDD` */
export const COLOR_MENTAL_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-mental-60)` as const
export const COLOR_MENTAL_60_VALUE = cssvarValue('color-mental-60')

/** In `light` theme is `#7A2C9D` */
export const COLOR_MENTAL_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-mental-80)` as const
export const COLOR_MENTAL_80_VALUE = cssvarValue('color-mental-80')

/** In `light` theme is `#CCF5E0` */
export const COLOR_NUTRITION_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-nutrition-20)` as const
export const COLOR_NUTRITION_20_VALUE = cssvarValue('color-nutrition-20')

/** In `light` theme is `#83A8A4` */
export const COLOR_NUTRITION_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-nutrition-40)` as const
export const COLOR_NUTRITION_40_VALUE = cssvarValue('color-nutrition-40')

/** In `light` theme is `#225559` */
export const COLOR_NUTRITION_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-nutrition-60)` as const
export const COLOR_NUTRITION_60_VALUE = cssvarValue('color-nutrition-60')

/** In `light` theme is `#1F4042` */
export const COLOR_NUTRITION_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-nutrition-80)` as const
export const COLOR_NUTRITION_80_VALUE = cssvarValue('color-nutrition-80')

/** In `light` theme is `#FC8DFE` */
export const COLOR_ACCENT = `var(--${DESIGN_TOKENS_PREFIX}-color-accent)` as const
export const COLOR_ACCENT_VALUE = cssvarValue('color-accent')

/** In `light` theme is `#7A59F5` */
export const COLOR_PRIMARY = `var(--${DESIGN_TOKENS_PREFIX}-color-primary)` as const
export const COLOR_PRIMARY_VALUE = cssvarValue('color-primary')

/** In `light` theme is `#FFFFFF` */
export const COLOR_WHITE = `var(--${DESIGN_TOKENS_PREFIX}-color-white)` as const
export const COLOR_WHITE_VALUE = cssvarValue('color-white')

/** In `light` theme is `#0B0B1E` */
export const COLOR_BLACK = `var(--${DESIGN_TOKENS_PREFIX}-color-black)` as const
export const COLOR_BLACK_VALUE = cssvarValue('color-black')

/** In `light` theme is `rgba(0, 0, 0, 0)` */
export const COLOR_TRANSPARENT = `var(--${DESIGN_TOKENS_PREFIX}-color-transparent)` as const
export const COLOR_TRANSPARENT_VALUE = cssvarValue('color-transparent')

/** In `light` theme is `#419F77` */
export const COLOR_SUCCESS = `var(--${DESIGN_TOKENS_PREFIX}-color-success)` as const
export const COLOR_SUCCESS_VALUE = cssvarValue('color-success')

/** In `light` theme is `#007AFF` */
export const COLOR_INFO = `var(--${DESIGN_TOKENS_PREFIX}-color-info)` as const
export const COLOR_INFO_VALUE = cssvarValue('color-info')

/** In `light` theme is `#FFAB00` */
export const COLOR_WARNING = `var(--${DESIGN_TOKENS_PREFIX}-color-warning)` as const
export const COLOR_WARNING_VALUE = cssvarValue('color-warning')

/** In `light` theme is `#FF5630` */
export const COLOR_ERROR = `var(--${DESIGN_TOKENS_PREFIX}-color-error)` as const
export const COLOR_ERROR_VALUE = cssvarValue('color-error')

/** In `light` theme is `#FF5630` */
export const COLOR_RED = `var(--${DESIGN_TOKENS_PREFIX}-color-red)` as const
export const COLOR_RED_VALUE = cssvarValue('color-red')

/** In `light` theme is `#419F77` */
export const COLOR_GREEN = `var(--${DESIGN_TOKENS_PREFIX}-color-green)` as const
export const COLOR_GREEN_VALUE = cssvarValue('color-green')

/** In `light` theme is `#FFAB00` */
export const COLOR_YELLOW = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow)` as const
export const COLOR_YELLOW_VALUE = cssvarValue('color-yellow')

/** In `light` theme is `#007AFF` */
export const COLOR_BLUE = `var(--${DESIGN_TOKENS_PREFIX}-color-blue)` as const
export const COLOR_BLUE_VALUE = cssvarValue('color-blue')

/** In `light` theme is `#CAFACB` */
export const COLOR_MINTTEA = `var(--${DESIGN_TOKENS_PREFIX}-color-minttea)` as const
export const COLOR_MINTTEA_VALUE = cssvarValue('color-minttea')

/** In `light` theme is `#AE4FDD` */
export const COLOR_MENTAL = `var(--${DESIGN_TOKENS_PREFIX}-color-mental)` as const
export const COLOR_MENTAL_VALUE = cssvarValue('color-mental')

/** In `light` theme is `#225559` */
export const COLOR_NUTRITION = `var(--${DESIGN_TOKENS_PREFIX}-color-nutrition)` as const
export const COLOR_NUTRITION_VALUE = cssvarValue('color-nutrition')

/** In `light` theme is `#FFFFFF` */
export const COLOR_GREY_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-10)` as const
export const COLOR_GREY_10_VALUE = cssvarValue('color-grey-10')

/** In `light` theme is `#F6F7FD` */
export const COLOR_GREY_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-20)` as const
export const COLOR_GREY_20_VALUE = cssvarValue('color-grey-20')

/** In `light` theme is `#ECEDF9` */
export const COLOR_GREY_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-30)` as const
export const COLOR_GREY_30_VALUE = cssvarValue('color-grey-30')

/** In `light` theme is `#D7D7EA` */
export const COLOR_GREY_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-40)` as const
export const COLOR_GREY_40_VALUE = cssvarValue('color-grey-40')

/** In `light` theme is `#A7A7D2` */
export const COLOR_GREY_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-50)` as const
export const COLOR_GREY_50_VALUE = cssvarValue('color-grey-50')

/** In `light` theme is `#8686C1` */
export const COLOR_GREY_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-60)` as const
export const COLOR_GREY_60_VALUE = cssvarValue('color-grey-60')

/** In `light` theme is `#7A7AAE` */
export const COLOR_GREY_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-70)` as const
export const COLOR_GREY_70_VALUE = cssvarValue('color-grey-70')

/** In `light` theme is `#5D5D98` */
export const COLOR_GREY_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-80)` as const
export const COLOR_GREY_80_VALUE = cssvarValue('color-grey-80')

/** In `light` theme is `#3E3E79` */
export const COLOR_GREY_90 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-90)` as const
export const COLOR_GREY_90_VALUE = cssvarValue('color-grey-90')

/** In `light` theme is `#2D2D58` */
export const COLOR_GREY_100 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-100)` as const
export const COLOR_GREY_100_VALUE = cssvarValue('color-grey-100')

/** In `light` theme is `#1C1C36` */
export const COLOR_GREY_110 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-110)` as const
export const COLOR_GREY_110_VALUE = cssvarValue('color-grey-110')

/** In `light` theme is `#0B0B1E` */
export const COLOR_GREY_120 = `var(--${DESIGN_TOKENS_PREFIX}-color-grey-120)` as const
export const COLOR_GREY_120_VALUE = cssvarValue('color-grey-120')

/** In `light` theme is `#FFFFFF` */
export const COLOR_LIGHTER = `var(--${DESIGN_TOKENS_PREFIX}-color-lighter)` as const
export const COLOR_LIGHTER_VALUE = cssvarValue('color-lighter')

/** In `light` theme is `#1C1C36` */
export const COLOR_DARKER = `var(--${DESIGN_TOKENS_PREFIX}-color-darker)` as const
export const COLOR_DARKER_VALUE = cssvarValue('color-darker')

/** In `light` theme is `#FFFFFF` */
export const COLOR_NEUTRAL_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-10)` as const
export const COLOR_NEUTRAL_10_VALUE = cssvarValue('color-neutral-10')

/** In `light` theme is `#F6F7FD` */
export const COLOR_NEUTRAL_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-20)` as const
export const COLOR_NEUTRAL_20_VALUE = cssvarValue('color-neutral-20')

/** In `light` theme is `#ECEDF9` */
export const COLOR_NEUTRAL_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-30)` as const
export const COLOR_NEUTRAL_30_VALUE = cssvarValue('color-neutral-30')

/** In `light` theme is `#D7D7EA` */
export const COLOR_NEUTRAL_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-40)` as const
export const COLOR_NEUTRAL_40_VALUE = cssvarValue('color-neutral-40')

/** In `light` theme is `#A7A7D2` */
export const COLOR_NEUTRAL_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-50)` as const
export const COLOR_NEUTRAL_50_VALUE = cssvarValue('color-neutral-50')

/** In `light` theme is `#8686C1` */
export const COLOR_NEUTRAL_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-60)` as const
export const COLOR_NEUTRAL_60_VALUE = cssvarValue('color-neutral-60')

/** In `light` theme is `#7A7AAE` */
export const COLOR_NEUTRAL_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-70)` as const
export const COLOR_NEUTRAL_70_VALUE = cssvarValue('color-neutral-70')

/** In `light` theme is `#5D5D98` */
export const COLOR_NEUTRAL_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-80)` as const
export const COLOR_NEUTRAL_80_VALUE = cssvarValue('color-neutral-80')

/** In `light` theme is `#3E3E79` */
export const COLOR_NEUTRAL_90 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-90)` as const
export const COLOR_NEUTRAL_90_VALUE = cssvarValue('color-neutral-90')

/** In `light` theme is `#2D2D58` */
export const COLOR_NEUTRAL_100 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-100)` as const
export const COLOR_NEUTRAL_100_VALUE = cssvarValue('color-neutral-100')

/** In `light` theme is `#1C1C36` */
export const COLOR_NEUTRAL_110 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-110)` as const
export const COLOR_NEUTRAL_110_VALUE = cssvarValue('color-neutral-110')

/** In `light` theme is `#0B0B1E` */
export const COLOR_NEUTRAL_120 = `var(--${DESIGN_TOKENS_PREFIX}-color-neutral-120)` as const
export const COLOR_NEUTRAL_120_VALUE = cssvarValue('color-neutral-120')

/** In `light` theme is `#FFE9FD` */
export const COLOR_ACCENT_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-10)` as const
export const COLOR_ACCENT_10_VALUE = cssvarValue('color-accent-10')

/** In `light` theme is `#FFD1FB` */
export const COLOR_ACCENT_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-20)` as const
export const COLOR_ACCENT_20_VALUE = cssvarValue('color-accent-20')

/** In `light` theme is `#FEA7F9` */
export const COLOR_ACCENT_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-30)` as const
export const COLOR_ACCENT_30_VALUE = cssvarValue('color-accent-30')

/** In `light` theme is `#FD86F6` */
export const COLOR_ACCENT_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-40)` as const
export const COLOR_ACCENT_40_VALUE = cssvarValue('color-accent-40')

/** In `light` theme is `#FC8DFE` */
export const COLOR_ACCENT_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-50)` as const
export const COLOR_ACCENT_50_VALUE = cssvarValue('color-accent-50')

/** In `light` theme is `#E569DC` */
export const COLOR_ACCENT_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-60)` as const
export const COLOR_ACCENT_60_VALUE = cssvarValue('color-accent-60')

/** In `light` theme is `#CA50B7` */
export const COLOR_ACCENT_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-70)` as const
export const COLOR_ACCENT_70_VALUE = cssvarValue('color-accent-70')

/** In `light` theme is `#A83C94` */
export const COLOR_ACCENT_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-accent-80)` as const
export const COLOR_ACCENT_80_VALUE = cssvarValue('color-accent-80')

/** In `light` theme is `#EBE9FE` */
export const COLOR_PRIMARY_10 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-10)` as const
export const COLOR_PRIMARY_10_VALUE = cssvarValue('color-primary-10')

/** In `light` theme is `#D9D6FE` */
export const COLOR_PRIMARY_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-20)` as const
export const COLOR_PRIMARY_20_VALUE = cssvarValue('color-primary-20')

/** In `light` theme is `#BDB5FD` */
export const COLOR_PRIMARY_30 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-30)` as const
export const COLOR_PRIMARY_30_VALUE = cssvarValue('color-primary-30')

/** In `light` theme is `#9D8CF9` */
export const COLOR_PRIMARY_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-40)` as const
export const COLOR_PRIMARY_40_VALUE = cssvarValue('color-primary-40')

/** In `light` theme is `#7A59F5` */
export const COLOR_PRIMARY_50 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-50)` as const
export const COLOR_PRIMARY_50_VALUE = cssvarValue('color-primary-50')

/** In `light` theme is `#6B3BEC` */
export const COLOR_PRIMARY_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-60)` as const
export const COLOR_PRIMARY_60_VALUE = cssvarValue('color-primary-60')

/** In `light` theme is `#5D29D8` */
export const COLOR_PRIMARY_70 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-70)` as const
export const COLOR_PRIMARY_70_VALUE = cssvarValue('color-primary-70')

/** In `light` theme is `#4D22B5` */
export const COLOR_PRIMARY_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-primary-80)` as const
export const COLOR_PRIMARY_80_VALUE = cssvarValue('color-primary-80')

/** In `light` theme is `#C8EEDE` */
export const COLOR_GREEN_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-20)` as const
export const COLOR_GREEN_20_VALUE = cssvarValue('color-green-20')

/** In `light` theme is `#7AD7AF` */
export const COLOR_GREEN_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-40)` as const
export const COLOR_GREEN_40_VALUE = cssvarValue('color-green-40')

/** In `light` theme is `#419F77` */
export const COLOR_GREEN_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-60)` as const
export const COLOR_GREEN_60_VALUE = cssvarValue('color-green-60')

/** In `light` theme is `#227251` */
export const COLOR_GREEN_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-green-80)` as const
export const COLOR_GREEN_80_VALUE = cssvarValue('color-green-80')

/** In `light` theme is `#FFDF99` */
export const COLOR_YELLOW_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-20)` as const
export const COLOR_YELLOW_20_VALUE = cssvarValue('color-yellow-20')

/** In `light` theme is `#FFCA5C` */
export const COLOR_YELLOW_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-40)` as const
export const COLOR_YELLOW_40_VALUE = cssvarValue('color-yellow-40')

/** In `light` theme is `#FFAB00` */
export const COLOR_YELLOW_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-60)` as const
export const COLOR_YELLOW_60_VALUE = cssvarValue('color-yellow-60')

/** In `light` theme is `#D05E06` */
export const COLOR_YELLOW_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-yellow-80)` as const
export const COLOR_YELLOW_80_VALUE = cssvarValue('color-yellow-80')

/** In `light` theme is `#CCE7FF` */
export const COLOR_BLUE_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-20)` as const
export const COLOR_BLUE_20_VALUE = cssvarValue('color-blue-20')

/** In `light` theme is `#66B8FF` */
export const COLOR_BLUE_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-40)` as const
export const COLOR_BLUE_40_VALUE = cssvarValue('color-blue-40')

/** In `light` theme is `#007AFF` */
export const COLOR_BLUE_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-60)` as const
export const COLOR_BLUE_60_VALUE = cssvarValue('color-blue-60')

/** In `light` theme is `#004299` */
export const COLOR_BLUE_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-blue-80)` as const
export const COLOR_BLUE_80_VALUE = cssvarValue('color-blue-80')

/** In `light` theme is `#FFE2D4` */
export const COLOR_RED_20 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-20)` as const
export const COLOR_RED_20_VALUE = cssvarValue('color-red-20')

/** In `light` theme is `#FFC0A8` */
export const COLOR_RED_40 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-40)` as const
export const COLOR_RED_40_VALUE = cssvarValue('color-red-40')

/** In `light` theme is `#FF5630` */
export const COLOR_RED_60 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-60)` as const
export const COLOR_RED_60_VALUE = cssvarValue('color-red-60')

/** In `light` theme is `#D01406` */
export const COLOR_RED_80 = `var(--${DESIGN_TOKENS_PREFIX}-color-red-80)` as const
export const COLOR_RED_80_VALUE = cssvarValue('color-red-80')
