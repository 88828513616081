import { useLayoutEffect, useState } from 'react'

type WindowSize = {
  width: number | undefined
  height: number | undefined
}

export function useWindowSize() {
  const [size, setSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })

  useLayoutEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}
