/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const SPACING_0 = `var(--${DESIGN_TOKENS_PREFIX}-spacing-0)` as const
export const SPACING_0_VALUE = '0'

/** `2px` */
export const SPACING_4XS = `var(--${DESIGN_TOKENS_PREFIX}-spacing-4xs)` as const
export const SPACING_4XS_VALUE = '2px'

/** `4px` */
export const SPACING_3XS = `var(--${DESIGN_TOKENS_PREFIX}-spacing-3xs)` as const
export const SPACING_3XS_VALUE = '4px'

/** `6px` */
export const SPACING_2XS = `var(--${DESIGN_TOKENS_PREFIX}-spacing-2xs)` as const
export const SPACING_2XS_VALUE = '6px'

/** `8px` */
export const SPACING_XS = `var(--${DESIGN_TOKENS_PREFIX}-spacing-xs)` as const
export const SPACING_XS_VALUE = '8px'

/** `12px` */
export const SPACING_SM = `var(--${DESIGN_TOKENS_PREFIX}-spacing-sm)` as const
export const SPACING_SM_VALUE = '12px'

/** `16px` */
export const SPACING_MD = `var(--${DESIGN_TOKENS_PREFIX}-spacing-md)` as const
export const SPACING_MD_VALUE = '16px'

/** `24px` */
export const SPACING_LG = `var(--${DESIGN_TOKENS_PREFIX}-spacing-lg)` as const
export const SPACING_LG_VALUE = '24px'

/** `32px` */
export const SPACING_XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-xl)` as const
export const SPACING_XL_VALUE = '32px'

/** `40px` */
export const SPACING_2XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-2xl)` as const
export const SPACING_2XL_VALUE = '40px'

/** `48px` */
export const SPACING_3XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-3xl)` as const
export const SPACING_3XL_VALUE = '48px'

/** `64px` */
export const SPACING_4XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-4xl)` as const
export const SPACING_4XL_VALUE = '64px'

/** `80px` */
export const SPACING_5XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-5xl)` as const
export const SPACING_5XL_VALUE = '80px'

/** `96px` */
export const SPACING_6XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-6xl)` as const
export const SPACING_6XL_VALUE = '96px'

/** `112px` */
export const SPACING_7XL = `var(--${DESIGN_TOKENS_PREFIX}-spacing-7xl)` as const
export const SPACING_7XL_VALUE = '112px'
