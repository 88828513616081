/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { DESIGN_TOKENS_PREFIX } from '../utils'

/** `0` */
export const TIME_0 = `var(--${DESIGN_TOKENS_PREFIX}-time-0)` as const
export const TIME_0_VALUE = '0'

/** `150ms` */
export const TIME_150 = `var(--${DESIGN_TOKENS_PREFIX}-time-150)` as const
export const TIME_150_VALUE = '150ms'

/** `300ms` */
export const TIME_300 = `var(--${DESIGN_TOKENS_PREFIX}-time-300)` as const
export const TIME_300_VALUE = '300ms'

/** `500ms` */
export const TIME_500 = `var(--${DESIGN_TOKENS_PREFIX}-time-500)` as const
export const TIME_500_VALUE = '500ms'

/** `700ms` */
export const TIME_700 = `var(--${DESIGN_TOKENS_PREFIX}-time-700)` as const
export const TIME_700_VALUE = '700ms'
