import { COLOR_WHITE } from 'design-tokens'
import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisLeft = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#left-clip-path)">
      <g clipPath="url(#left-clip-path-2)">
        <circle cx="12" cy="12" fill={color} fillOpacity=".7" r="12" />
        <path d="m14 7-5 5 5 5" stroke={COLOR_WHITE} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      </g>
    </g>
    <defs>
      <clipPath id="left-clip-path">
        <path d="M0 0h24v24H0z" fill={COLOR_WHITE} />
      </clipPath>
      <clipPath id="left-clip-path-2">
        <path d="M0 0h24v24H0z" fill={COLOR_WHITE} />
      </clipPath>
    </defs>
  </svg>
)
